import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorSzymon as author } from 'data/authors';

import img from 'img/blog/updates/Recording_rules.png';
import video1 from 'video/updates/Recording_rules_1.webm';
import video2 from 'video/updates/Recording_rules_2.webm';
import styled from 'styled-components';
import VideoModal from 'components/ui/video-modal';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`;

const Content = () => {
  return (
    <Container>
      <h3>How to set Recording Rules?</h3>
      <ul>
        <li>
          Log in to{' '}
          <a href="https://app.livesession.io" alt="livesession-app">
            app.livesession.io
          </a>
        </li>
        <li>Go to settings </li>
        <li>Click on &quot;Recording rules&quot; next to the website you added</li>
      </ul>

      <p className="mt-5 mb-5">
        <VideoModal src={{ webm: video1 }} />
      </p>

      <ul>
        <li>Press the &quot;+&quot; icon to add new rule</li>
        <li>Then select rule type (URL, IP, User Agent or Country)</li>
        <li>Select a condition for this rule</li>
        <li>Enter a value for the selected rule type</li>
        <li>And press &quot;Update&quot; or add another rule</li>
      </ul>

      <p className="mt-5 mb-5">
        <VideoModal src={{ webm: video2 }} />
      </p>

      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Recording rules',
  url: '/blog/recording-rules/',
  description: 'Set Recording Rules for your site to record only if chosen conditions fulfilled.',
  author,
  img,
  date: '2019-07-22',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
  />
);
